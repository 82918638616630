import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import ApplyDiscount from '../ApplyDiscount'
import { verifyCouponCode } from '../../../api'
import { getPageContent } from '../../../api/cms'
import { raiseToast } from '../../../utils/toast'
import "./Payment.css"

// modified by kashif
import Web3 from 'web3';
import Creativecopyrights from '../../../abis/Creativecopyrights.json';

const privateKeys = process.env.REACT_APP_PRIVATE_KEYS || ""
// create instance of ipfsclient
const {create} = require('ipfs-http-client')
const ipfs = create({ host: 'ipfs.infura.io', port: 5001, protocol: 'https' }) 
// infuara api url from config
const infuara_api_url = process.env.REACT_APP_INFURA_URL
// rinkeby address from config
const address = process.env.REACT_APP_RINKBEY_ADDRESS || ""

// https://developer.paypal.com/docs/checkout/
// Only Paypal + Credit Card

// const PP_CLIENT_ID = 'AdP-sbkzdRSRri0y42qfrqZpZ4_1HQJvDj4prJX0zuP57GCMgKPLhnLtIqZsAiq00LziPC7h06a9nwln'
const PP_CLIENT_ID = 'AR7w4f35_jng9FYPS7qr8NWCkZh5wxv7JbfL29NfGCM4yOLhm8eRrZAWeoBC_RnTrPaHRLsCKQBo8Ssw'

const Wrapper = styled.div`
    padding: 35px 35px;

    >div:first-child {
        border-bottom: 1px solid #004c4c;
    }
`;

const PaymentOption = styled.div`
    color: #004c4c;
    padding: 15px 0;
`;

const Title = styled.div`
    text-transform: uppercase;
    font-size: 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;
    font-weight: bold;
`;
const Description = styled.div`
  font-size: 16px;
  font-weight: 600;
  line-height: 1.4;
  margin-bottom: 18px;
`;
const Price = styled.div`
  font-weight: 800;
  font-size: 28px;
`;

const PayPalWrapper = styled.div`
    padding: 20px 0;
`;

const Payment = ({ email, setActiveStage, name, file, setFileData }) => {
    const [isPayPalLoaded, setIsPayPalLoaded] = useState(false)
    const [couponCode, setCouponCode] = useState(null)
    const [cost, setCost] = useState(0)
    const [isCouponDisabled, setCouponDisabled] = useState(false)
    
    useEffect(() => {
        getPageContent({ page: 'pricing' })
        .then(({ data }) => setCost(data[0].servicecost))
    }, [])

    React.useEffect(() => {
        if (isPayPalLoaded) { return }
        if (cost<=0) {return}
        window.paypal && window.paypal.Button.render({
            // env: 'production',
            env: 'sandbox',
            client: {
            sandbox: PP_CLIENT_ID,
            //   production: PP_CLIENT_ID
            },
            locale: 'en_US',
            style: {
                size: 'responsive',
                color: 'gold',
                shape: 'pill',
            },
            funding: {
                allowed: [
                    // window.paypal.FUNDING.PAYPAL,
                    window.paypal.FUNDING.CREDIT
                ]
            },

            // Enable Pay Now checkout flow (optional)
            commit: true,

            onInit: () => {
                setIsPayPalLoaded(true)
            },

            // Set up a payment
            payment: function(data, actions) {
            return actions.payment.create({
                transactions: [{
                amount: {
                    total: cost,
                    currency: 'EUR'
                }
                }]
            });
            },

            // Execute the payment
            onAuthorize: function(data, actions) {
            return actions.payment.execute().then(function(response) {
                raiseToast('Transaction completed by ' + response.payer?.payer_info?.email + '!')
                const reader = new window.FileReader()
                reader.readAsArrayBuffer(file)
                reader.onloadend = () => {
                    // execute blockchain transaction
                    uploadFile(Buffer(reader.result), file.type, file.name, file.size)
                }
            });
            }

        }, '#paypal-button-container');

    }, [cost])

    // function added by kashif
    const uploadFile =  async (buffer, type, path, size) => {
        // Add file to the IPFS
        const result = await ipfs.add(buffer)
        if(type === ''){
            type = 'none';
        }
    
        let t_Hash = "";
        const web3 = new Web3(infuara_api_url);
        const networkId = await web3.eth.net.getId();
        const networkData = Creativecopyrights.networks[networkId]
        if(networkData) {
            // Assign contract
            const creativecopyrights = new web3.eth.Contract(Creativecopyrights.abi, networkData.address, {transactionConfirmationBlocks: 0})
            // add private key to wallet
            web3.eth.accounts.wallet.add(privateKeys);

            const tx = creativecopyrights.methods.uploadFile(result.path, result.size, type, path, name)
            .send({ from: address, gasPrice:25000000000,gas:6700000 ,transactionConfirmationBlocks:3})
            .on('transactionHash', (transactionHash) => {
                setFileData({
                    name:name,
                    file_name:path,
                    file_hash:result.path,
                    transactionHash:transactionHash
                })
                setActiveStage(2);
            })
            .on('error', (e) =>{
                console.log(e);
            });

            console.log(tx);
        }else{
            alert("problem with contract");
        }
    }

    const applyCouponCode = () => {
        verifyCouponCode(couponCode)
        .then((isSucceed) => {
            if (isSucceed) {
                raiseToast('Coupon Code is valid and applied!')

                const discountPercentage = couponCode.split('-')[1]
                const newCost = cost * (100-discountPercentage) / 100

                setCost(newCost)
                setCouponDisabled(true)
            } else {
                raiseToast('Coupon Code is invalid, try again.')
            }
        })
    }

    return (
        <Wrapper className="paymentContainer" >

            <PaymentOption>
                <Title>
                    1 file copyright proof
                    <Price>€ {cost}</Price>
                </Title>
                <Description>Your Certificate is e-mailed to:<br/>{email}</Description>
            </PaymentOption>

            <PaymentOption>
                <Title>
                    &nbsp;
                    <Price>€ {cost}</Price>
                </Title>
            </PaymentOption>

            <ApplyDiscount onClick={applyCouponCode}
                setValue={setCouponCode}
                disabled={isCouponDisabled}/>

            <PayPalWrapper id="paypal-button-container"/>

        </Wrapper>
    )
}

export default Payment
